import React from 'react';
import picture from '..//../assets/images/Picture1.jpeg';
import { useNavigate } from 'react-router-dom';
import './SecondPage.css';
const SecondPage: React.FC = () => {

    const navigate = useNavigate();

  const handleRedirect = (route: string) => {
    navigate(route);
  };

  return (
    <div className='SecondPage'>
        <>
        <h2>This is the second part of a two-part experiment. In this part, you will be asked to choose between two consumption plans 19 times with each plan covering three periods. More specifically, each consumption plan will consist of three numbers representing allowances at different times (with a two-month gap). For example, the plan: </h2>
      <img src={picture} alt="pic" />
      <p>represents the following allowances: In two months, you will receive 3 GPB, in four months you will receive 5 GPB, and in six months you will receive 1 GBP.
         Therefore, if you choose this consumption plan, you can receive a total of 5+3+1=9 GBP within 6 months (in addition to the regular payment from prolific).
          Ultimately, one out of every four participants who finish the two parts of the experiment will be chosen randomly. These participants will receive one of their choices in the experiment (which will also be chosen randomly).</p>
      <br />
      <p>Click 'Continue' if you think you understand the nature of the tasks in the experiment:</p>
      </>
      <div className='button-container'>
        
        
        <button onClick={() => handleRedirect('/survey')}>Continue</button>
        <button onClick={() => handleRedirect('/bye')}>Stop</button>
      </div>
    </div>
    
    
  );
};

export default SecondPage;