import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FirstPage.css';
import { useSelector, useDispatch } from 'react-redux';
import { getProlificParticipantId } from '../../services/ProlificService/ProlificService';
import { setUUID } from '../../redux/uuid/idSlice';




const FirstPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const prolificId = getProlificParticipantId(location.search);

    // Dispatch the prolificId to the Redux store
    dispatch(setUUID(prolificId!));
  }, [location, dispatch]);

  const handleRedirect = (route: string) => {
    navigate(route);
  };


  return (
    <div className='FirstPage'>
      <h2>
      Hello,
this is a questionnaire on the topic of consumption choices. 
There are no right or wrong answers in the questionnaire, and what is important to us is that you will answer candidly. 
The questionnaire is anonymous, and the data collected will be used for academic research purposes only. 
The expected time to answer the questionnaire is about 7 minutes, and you are free to stop the questionnaire at any time. 
For any problem, you can contact the research team by email: be@ruppin.ac.il.
      </h2>
      <div className="list-container">
        <h2>By clicking the 'Continue' button, you confirm that:</h2>
        <ol>
        <li>You agree to participate in the research as detailed above. </li>
<li>The purpose of the research was generally explained to you. </li>
<li>The expected duration of the research was explained to you. </li>
<li>You were informed that you are free to stop the research at any time you wish. </li>
<li>You were informed that the research is anonymous, and your personal details will not be saved. </li>
<li>You were informed that all the details collected will be used for academic research only. </li>
<li>You were informed that for any problem related to the research, you can contact the researcher for further consultation. </li>
<li>You hereby declare that you have given the above consent of your own free will and that you have understood all of the above. </li>
        </ol>
      </div>

      <div className="button-container">
      <button onClick={() => handleRedirect('/2')}>Continue</button>
        <button onClick={() => handleRedirect('/bye')}>Stop</button>
        
      </div>
    </div>
  );
};

export default FirstPage;
