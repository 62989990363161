// src/redux/uuid/idSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UUIDState {
  value: string;
}

const initialState: UUIDState = {
  value: '',
};

export const idSlice = createSlice({
  name: 'uuid',
  initialState,
  reducers: {
    setUUID: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setUUID } = idSlice.actions;

export default idSlice.reducer;
