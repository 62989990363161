import React, { useEffect } from 'react';
import './RandomNumbersComponent.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import generateRandomNumbersService from '../../services/GenerateRandomNumbersService/GenerateRandomNumbersService';
import QuestionPlusAnswerModel from '../../models/QuestionPlusAnswerModel/QuestionPlusAnswerModel';
import PlanCard from '../PlanCard/PlanCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateRandomNumbersServiceForTwo } from '../../services/GenerateRandomNumbersServiceForTwo/generateRandomNumbersServiceForTwo';
import LastPage from '../LastPage/LastPage';
import questionPlusAnswerService from '../../services/QuestionPlusAnswerService/QuestionPlusAnswerService';
import { getProlificParticipantId } from '../../services/ProlificService/ProlificService';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/uuid/store';

const RandomNumbersComponent: React.FC = () => {
  const [numbers, setNumbers] = React.useState<number[]>([]);
  const [showBlackScreen, setShowBlackScreen] = React.useState(false);

  const [anotherTwoNumbers, setAnotherTwoNumbers] = React.useState<number[]>(generateRandomNumbersServiceForTwo());
  const [couplesOfNumbers, setCouplesOfNumbers] = React.useState<QuestionPlusAnswerModel[]>([]);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [selectedPlan, setSelectedPlan] = React.useState<string>('');
  const [answersVsNumbers, setAnswersVsNumbers] = React.useState<QuestionPlusAnswerModel[]>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [exitMessage, setExitMessage] = React.useState<boolean>(false);
  const [messageContent, setMessageContent] = React.useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const idFromRedux1 = useSelector((state: RootState) => state.id.value);
    console.log(idFromRedux1);

  React.useEffect(() => {
    
    const prolificId = getProlificParticipantId(location.search);
    generateCouples(prolificId);

  }, []);

  const generateCouples = async(prolificId: any) => {
    
    const couples: QuestionPlusAnswerModel[] = [];
    console.log('dsfgsdfgsdf');
    console.log(idFromRedux1);
    const response = await questionPlusAnswerService.getAnswers(idFromRedux1);
    // questionPlusAnswerService.getAnswers().then((response) => {
     for(let i = 0; i < response.server_response.length; i++) {
      let couple: QuestionPlusAnswerModel = {};
      couple.firstNum = response.server_response[i]['firstNum'];
      couple.secondNum = response.server_response[i]['secondNum']
      couples.push(couple);
     }
    // })
    // .catch((error) => {
    //   toast.error(error.response);
    // });
    // navigate("/bye");
    // const newNumbers: number[] = generateRandomNumbersService();
    // setNumbers(newNumbers);

    // const couples: QuestionPlusAnswerModel[] = [];
    // for (let i = 0; i < newNumbers.length; i++) {
    //   for (let j = i + 1; j < newNumbers.length; j++) {
    //     let couple: QuestionPlusAnswerModel = {};
    //     couple.firstNum = newNumbers[i];
    //     couple.secondNum = newNumbers[j];
    //     couples.push(couple);
    //   }
    // }

    


    const getRandomValue = (arr: any[]): any => {
      const randomIndex = Math.floor(Math.random() * arr.length);
      const value = arr[randomIndex];
      //arr.splice(randomIndex, 1); // Remove the selected value from the array
      return value;
    };

    const generateRandomArray = (): any[] => {
      const shuffledArray = [...couples];
      const resultArray: any[] = [];
      while (shuffledArray.length > 0) {
        const randomValue = getRandomValue(shuffledArray);
        resultArray.push(randomValue);
      }
      return resultArray;
    };



    let finalArray = [...couples];

    // Insert anotherTwoNumbers at position 1 in couplesOfNumbers array
    const updatedCouples = [...finalArray];
  
    // updatedCouples.splice(1, 0, {
    //   firstNum: anotherTwoNumbers[0],
    //   secondNum: anotherTwoNumbers[1],
    // });

    // updatedCouples.splice(4, 0, {
    //   firstNum: 455,
    //   secondNum: 212,
    // });

    // updatedCouples.splice(7, 0, {
    //   firstNum: 234,
    //   secondNum: 345,
    // });



    let randomNumber = Math.floor(Math.random() * 5) + 1;

    while (Math.floor(anotherTwoNumbers[0] / 100) === randomNumber) {
      randomNumber = Math.floor(Math.random() * 5) + 1;
    }

    let newNumber1 = (randomNumber * 100) + (anotherTwoNumbers[0] % 100);
    let newNumber2 = (randomNumber * 100) + (anotherTwoNumbers[1] % 100);


    // updatedCouples.splice(16, 0, {
    //   firstNum: newNumber1,
    //   secondNum: newNumber2,
    // });


    setCouplesOfNumbers(updatedCouples);

    setCurrentIndex(0);
    setSelectedPlan('');
  };

  useEffect(() => {
    // When the component is mounted, push the current URL to the history
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (e: PopStateEvent) => {
        // When the popstate event is fired, push the current URL back to history
        window.history.pushState(null, document.title, window.location.href);
        e.preventDefault();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
        // Clean up the event listener when the component is unmounted
        window.removeEventListener('popstate', handlePopState);
    };
}, []);

  const generateNextCouple = () => {
    
    if (!selectedPlan) {
      showToastMessage("אנא בחרו תוכנית לפני המשך");
      return;
    }
  
    // Add the selected plan's answer to the array after clicking the "לחץ להמשך" button
    const currentNumbers = couplesOfNumbers[currentIndex];
    const answer = {
      firstNum: currentNumbers.firstNum,
      secondNum: currentNumbers.secondNum,
      answer: selectedPlan === 'Plan A',
    };
    setAnswersVsNumbers((prevState) => [...prevState, answer]);
  
    if (currentIndex < couplesOfNumbers.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedPlan('');
  
      if (currentIndex === 3) {
        // showToastMessage("!!!שים לב");
        showToastMessage("In the next question you are asked to choose “Plan Y” regardless of your preferences.");
      }
      // if (currentIndex === 6) {
      //   // showToastMessage("!!!שים לב");
      //   showToastMessage("בשאלה הבאה נרצה לראות שאתה מרוכז ולכן בשאלה זו תתבקשו לבחור את תוכנית א' ללא קשר להעדפות האמיתיות שלך");
      // }
    } else {
      // Last couple, add the answer and navigate to the 'bye' page
      // const lastCoupleAnswer = {
      //   firstNum: currentNumbers.firstNum,
      //   secondNum: currentNumbers.secondNum,
      //   answer: selectedPlan === 'Plan A',
      // };
      // setAnswersVsNumbers((prevState) => [...prevState, lastCoupleAnswer]);

      // // Navigate to the 'bye' page
      // showToastMessage("סוף השאלון, תודה רבה על שיתוף הפעולה");
      // if(answersVsNumbers.length ===19){
      //   return <LastPage/>
      //   // navigate("/bye");
      // }
    }
    if (currentIndex === 19) {
      // This will trigger the navigation after the component has finished rendering
      setExitMessage(true);
    }
    // if(currentIndex === 19){
    //   // return <LastPage/>
    //   navigate("/bye");
    // }

    setShowBlackScreen(true);
    setTimeout(() => {
        setShowBlackScreen(false);
    }, 1000); // 2 seconds
  };


  React.useEffect(() => {
    if (exitMessage) {
      // Navigate to the 'bye' page
      navigate("/bye");
    }
  }, [exitMessage]);

  const handleSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handlePlanA = () => {
    handleSelectPlan('Plan A');
  };

  const handlePlanB = () => {
    handleSelectPlan('Plan B');
  };

  const handleOkButtonClick = () => {
    setShowMessage(false);
  };

  const showToastMessage = (message: string) => {
    setMessageContent(message);
    setShowMessage(true);

    setTimeout(() => {
      setShowMessage(false);
  }, 8000); // 8 seconds
  };



    // const location = useLocation();
    // const prolificId = getProlificParticipantId(location.search);
    // console.log("prolific Id:" )
    // console.log(prolificId)
    const idFromRedux = useSelector((state: RootState) => state.id.value);


  if(answersVsNumbers.length === 19){
    for(let i = 0; i < answersVsNumbers.length; i++ ) {
      answersVsNumbers[i].indexInArr = i;
      answersVsNumbers[i].uniqueUserid = idFromRedux!;

    }
    questionPlusAnswerService.postAnswers(answersVsNumbers).then((response) => {

    })
    .catch((error) => {
      toast.error(error.response);
    });
    navigate("/bye");
  }

  return (
    <div className='RandomNumbersComponent'>
      <ToastContainer />
      {answersVsNumbers.length === 19 ? (
        // Render the "LastPage" component when answersVsNumbers.length is 19
        <LastPage />
      ) : (
        // Otherwise, render the main content of the RandomNumbersComponent
        <>
          <h2> Question {currentIndex + 1} </h2>
          <h2>Would you prefer plan X or plan Y?</h2>
          {/* Render the div for the current couple */}
          {couplesOfNumbers.length > 0 && (
            <div className='couple'>
              <div className='column1'>
                <PlanCard title={'Plan X'} children={couplesOfNumbers[currentIndex].firstNum} />
              </div>

              <div className='column2'>
                <PlanCard title={'Plan Y'} children={couplesOfNumbers[currentIndex].secondNum} />
              </div>
            </div>
            
          )}

          {/* Buttons for plan selection */}
          <div className='planSelectionButtons'>
            
            <button className={`planButton ${selectedPlan === 'Plan A' ? 'active' : ''}`} onClick={handlePlanA}>
            Plan X
            </button>
            <button className={`planButton ${selectedPlan === 'Plan B' ? 'active' : ''}`} onClick={handlePlanB}>
            Plan Y
            </button>
          </div>

          {/* Button to generate next couple */}
          <button onClick={generateNextCouple} disabled={!selectedPlan}>
          Click to continue
          </button>

          {/* Button to regenerate couples */}
          {/* <button onClick={generateCouples}>Regenerate</button> */}

          {showMessage && (
            <div className={`messageDiv ${currentIndex === 5 ? 'show' : ''}`}>
              <div className='messageContent'>
                {messageContent}
              </div>
              {/* <button onClick={handleOkButtonClick}>OK</button> */}
            </div>
          )}
        </>
      )}
      {showBlackScreen && <div className="blackScreen"></div>}
    </div>
  );
};

export default RandomNumbersComponent;