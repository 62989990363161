export const generateRandomNumbersServiceForTwo = (): number[] => {
  const numbers: number[] = [];

  while (numbers.length < 2) {
    const randomNumber = Math.floor(Math.random() * (555 - 111 + 1)) + 111;

    if (
      !String(randomNumber).split('').some((digit) => Number(digit) > 5) &&
      !String(randomNumber).split('').some((digit) => Number(digit) < 1) &&
      !numbers.includes(randomNumber)
    ) {
      numbers.push(randomNumber);
    }
  }

  // console.log("numbers")
  // console.log(numbers)
  const sortedNumbers: number[] = numbers.sort((a, b) => b - a);
  // console.log("sorted numbers")
  // console.log(sortedNumbers)
  let firstDigits: String[] = [];
  let secondDigits: String[] = [];


  if (sortedNumbers[0] > sortedNumbers[1]) {
    firstDigits = Array.from(String(sortedNumbers[0]));
    secondDigits = Array.from(String(sortedNumbers[1]));
  }
  else {
    firstDigits = Array.from(String(sortedNumbers[1]));
    secondDigits = Array.from(String(sortedNumbers[0]));
  }


  if (
    (firstDigits[0] === secondDigits[0]) && ((firstDigits[1] > secondDigits[1] && firstDigits[2] < secondDigits[2]) || (firstDigits[1] < secondDigits[1] && firstDigits[2] > secondDigits[2]))
  ) {
    return sortedNumbers;
  }
  else {
    return generateRandomNumbersServiceForTwo();
  }

};
