import React, { ReactNode } from 'react';
import './PlanCard.css';

interface PlanCardProps {
  title: string;
  children: ReactNode;
}

const PlanCard: React.FC<PlanCardProps> = ({ title, children }) => {
    const childrenValue = typeof children === 'number' ? children : 0;
  return (
    <div className="plan-card">
      <div className='cardHeader'>
            <h2>{title}</h2>
        </div>
        {
          <table>
    <tr>
        <th>How Much</th>
        <th>When</th>
    </tr>

    <tr>
        <td>{Math.floor(childrenValue / 100)} GBP</td>
        <td>Today</td>
    </tr>
    <tr>
        <td>{Math.floor(childrenValue / 10 % 10)} GBP</td>
        <td>In Two months</td>
    </tr>
    <tr>
        <td>{Math.floor(childrenValue % 10)} GBP</td>
        <td>In Four months</td>
    </tr>
</table>
        /* <div className='cardHeader'>
            <h2>{title}</h2>
        </div>
        <div className='cardSecondHeader'>
           
            <div className='howMany'>
                <span className='textSize'>how much</span>
            </div>
            <div className='when'>
                <span className='textSize'>when</span>
            </div>
            
        </div>

        <div className='cardBottom'>
        
        
        <div className='cardBottomRight'>
            <p>{Math.floor(childrenValue / 100)}</p>
            <p>{Math.floor(childrenValue / 10 % 10)}</p>
            <p>{Math.floor(childrenValue % 10)}</p>
        
        </div>
        <div className='cardBottomLeft'>
            <p>in two months</p>
            <p>in four months</p>
            <p>in six months</p>
        </div>
      
      
      </div> */}
    </div>
  );
};

export default PlanCard;
