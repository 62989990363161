import React, { useEffect, useState } from "react";
import { Router, useNavigate } from "react-router-dom";

import "./LayoutArea.css";

import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import FirstPage from "../FirstPage/FirstPage";
import Routing from "../Routing/Routing";




function LayoutArea() {
  

  return (
    <div className="LayoutArea">
      {/* <div className="header">
        <Navbar />
      </div> */}

      <div className="center">
       
        <div className="content">
         
         <Routing/>
       {/* <FirstPage/> */}
          
        </div>
      </div>

      {/* <div className="footer">
        <Footer />
      </div> */}
    </div>
  );
}

export default LayoutArea;
