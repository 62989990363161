import axios from "axios";
import appConfig from "../../Configurations/Config";
import QuestionPlusAnswerModel from "../../models/QuestionPlusAnswerModel/QuestionPlusAnswerModel";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/uuid/store';
import { getProlificParticipantId } from '../../services/ProlificService/ProlificService';


class QuestionPlusAnswerService {
  async postAnswers(questionsPlusAnswers: QuestionPlusAnswerModel[]): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append('arr', JSON.stringify(questionsPlusAnswers));

    const response = await axios({
      method: "post",
      url: 'api/add-questionnaire.php',
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      
      return response.data;
    }

    async getAnswers(prolificId: any): Promise<any> {
      const bodyFormData = new FormData();
      bodyFormData.append('surveyId', String(prolificId));
      const response = await axios({
        method: "post",
        url: 'api/get-questionnaire.php',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        return response.data;
      }
    
  }
  


const questionPlusAnswerService = new QuestionPlusAnswerService();
export default questionPlusAnswerService;
