import React, { useEffect } from 'react';
import './LastPage.css';
import { getProlificParticipantId } from '../../services/ProlificService/ProlificService';
import { useLocation } from 'react-router-dom';

const LastPage: React.FC = () => {
  useEffect(() => {
    // When the component is mounted, push the current URL to the history
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (e: PopStateEvent) => {
        // When the popstate event is fired, push the current URL back to history
        window.history.pushState(null, document.title, window.location.href);
        e.preventDefault();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
        // Clean up the event listener when the component is unmounted
        window.removeEventListener('popstate', handlePopState);
    };
}, []);

const redirectToProlific = () => {
  // Replace with the actual URL where users enter the completion code
  const prolificCompletionURL = 'https://app.prolific.com/submissions/complete?cc=CSC7ZLVA';
  window.location.href = prolificCompletionURL;
};
    
  return (
    <div className='lastPage'>
      <h2>End of Questionnaire,
Thank you for your cooperation.</h2>

<h2>Your completion code is: C1B2F8HI</h2>
<button onClick={redirectToProlific}>Return to Prolific</button>
    </div>
  );
};

export default LastPage;