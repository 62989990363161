import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import FirstPage from '../FirstPage/FirstPage';
import SecondPage from '../SecondPage/SecondPage';
import LastPage from '../LastPage/LastPage';
import RandomNumbersComponent from '../RandomNumbersComponent/RandomNumbersComponent';

function Routing(): JSX.Element {
  return (
    <Routes>
      {/* FirstPage */}
      <Route path="/" element={<FirstPage />} />

      {/* SecondPage */}
      <Route path="/2" element={<SecondPage />} />

       {/* LastPage */}
       <Route path="/bye" element={<LastPage />} />

       {/* Survey */}
       <Route path="/survey" element={<RandomNumbersComponent />} />

      {/* Redirect */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Routing;
